<template>
  <div>
    <Banner :data="dataBanner" />
    <JobOpenings :jobs="jobs" />
    <KeyBenefit />
    <Message />
    <div class="welcomeProject">
      <Welcome :data="welcome" />
    </div>
    <Footer />
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
const Banner = () => import("../components/Renew/Components/Banner.vue");
const JobOpenings = () =>
  import("../components/Renew/Recruitment/JobOpenings.vue");
const KeyBenefit = () =>
  import("../components/Renew/Recruitment/KeyBenefit.vue");
const Message = () => import("../components/Renew/Recruitment/Poem.vue");
const Welcome = () => import("../components/Renew/Components/Welcome.vue");
const Footer = () => import("../views/FooterNew.vue");

const HRM_URL = process.env.VUE_APP_API_URL;
// const TRACKING_KEY = process.env.VUE_APP_TRACKING_KEY;

export default {
  name: "Recruiment",
  data() {
    return {
      contact: this.$t("contact"),
      url: "https://tgl-sol.com/data.json/",
      data_sheets: null,
      checkSheet: false,
      recruiment: this.$t("recruiment"),
      dataBanner: {
        title: this.$t("recruiment").banner.title,
        images: "/images/recruitment/bg-banner-recruiment.webp",
        sub: this.$t("recruiment").banner.sub,
      },
      welcome: this.$t("recruiment").welcome,
      seo: this.$t("seo").recruiment,
      jobs: [],
    };
  },
  metaInfo() {
    return {
      title: this.seo.title,
      meta: [
        {
          name: "description",
          content: this.seo.description,
        },
        {
          property: "og:title",
          content: this.seo.title,
        },
        {
          property: "og:description",
          content: this.seo.description,
        },
      ],
    };
  },
  mounted() {
    // this.getDataRecruiment(this.contact.gsheet_url);
    this.handleGetJob();
    // this.postEventLoad();
  },
  methods: {
    async handleGetJob() {
      const res = await axios.get(`${HRM_URL}/jobs?page-size=150&status=1`);
      this.jobs = res.data.data.filter((job) => job.status && job.is_public);
    },
    async getDataRecruiment() {
      await axios.get(this.url).then(async (data) => {
        this.data_sheets = data.data.vn.filter((item) => {
          return item.flag === "1";
        });
        // if (localStorage.lang === 'vn') {
        //   this.data_sheets = data.data.vn.filter((item) => {
        //     return item.flag === '1';
        //   });
        // } else {
        //   this.data_sheets = data.data.en.filter((item) => {
        //     return item.flag === '1';
        //   });
        // }
        this.data_sheets.forEach((item) => {
          item.requirements = item.requirements.split("\n");
        });
        if (this.data_sheets.length === 0) {
          this.checkSheet = true;
        } else {
          this.checkSheet = false;
        }
      });
    },
    // async postEventLoad() {
    //   await axios.post("https://alink.wiki/api/v1/events", {
    //     project_token: TRACKING_KEY,
    //     category: "Recruiment Page",
    //     action: "onLoad",
    //   });
    // },
  },
  components: {
    Footer,
    Banner,
    JobOpenings,
    KeyBenefit,
    Message,
    Welcome,
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  watch: {
    lang() {
      this.contact = this.$t("contact");
      this.getDataRecruiment(this.contact.gsheet_url);
      this.recruiment = this.$t("recruiment");
      this.seo = this.$t("seo").recruiment;
      this.dataBanner = {
        title: this.$t("recruiment").banner.title,
        images: "/images/recruitment/bg-banner-recruiment.webp",
        sub: this.$t("recruiment").banner.sub,
      };
      this.welcome = this.$t("recruiment").welcome;
    },
  },
};
</script>

<style scoped>
.welcomeProject {
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 100%;
  }
}
</style>
